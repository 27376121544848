body {
  width: 100%;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --Sys-Labels-Primary: #000000;
  --Sys-Colors-Accent: #0A84FF;
  --Sys-Grays-White: #FFFFFF;
  --Sys-Fills-Tertiary: rgba(118, 118, 128, 0.12);
  --Sys-Labels-Tertiary: rgba(60, 60, 67, 0.30);
  --Sys-Overlay-Web-Progress: rgba(255, 255, 255, 0.95);
  --Sys-BG-Grouped-Secondary-Base: #FFFFFF;
  --Sys-Labels-Secondary: rgba(60, 60, 67, 0.60);
  --Sys-Elements-Card-Photo-Stroke: rgba(255, 255, 255, 0.12);
  --Sys-Fills-Primary: rgba(120, 120, 128, 0.36);
  --Sys-Separators-Non-opaque: rgba(60, 60, 67, 0.29);
  --Brand-Blue-700: #0564FF;
  --Brand-Blue-600-Key: #0A84FF;
  --Sys-Fills-Secondary: rgba(120, 120, 128, 0.32);
  --Sys-Colors-Red: #FF3B30;

  --Button-Button-Max-W: 390px;

  --Page-View-Corner-View: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::part(.yoti-face-capture-module) {
  color: blue;
}
.yoti-face-capture-module, ._yoti-face-capture-module-content_1p6hn_6 {
  color: blue;
}
.yoti-face-capture-module video {
  width: 100vw;
  height: 100vw;
}

.yoti-face-capture-module video + div {
  aspect-ratio: 1 / 1;
}

.preview-placeholder {
  margin: 0 auto;
  width: 100%;
  aspect-ratio: 388 / 245;
  background-color: #eee;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #F2F2F7 10%, #dddddd 18%, #F2F2F7 33%);
  background-size: 800px 404px;
  height: 100%;
  position: relative;
}
